import { Link } from "react-router-dom";
import etravelLogo from "../images/e-travel_logo_RGB.png";
import ModalImage from "react-modal-image";

import classes from "./style.scss";

const documents = [
    {
        title:"Удостоверение за извършване на туроператорска и туристическа агентска дейност",
        large:"https://etclub.bg/images/documents/udostoverenie_TO_TA-2022.jpeg"
    },
    {
        title:"Застраховка Отговорност",
        large:"https://etclub.bg/images/documents/ETC_insurance_2022_2023_certificate.png"
    },
    {
        title:"Удостоверение за лични данни",
        large:"https://etclub.bg/images/documents/udostoverenie_adm_lichni_danni.jpeg"
    }
]

const Footer = () => {
	return (
		<footer className={classes.footer}>
			<div>
                <ModalImage 
                    className={classes.hidden}
                    small={documents[0].title}
                    large={documents[0].large}
                    alt={documents[0].title}
                    hideDownload={true}
                />

                <div className={classes.separator}>/</div>

                <ModalImage 
                    className={classes.hidden}
                    small={documents[1].title}
                    large={documents[1].large}
                    alt={documents[1].title}
                    hideDownload={true}
                />

                <div className={classes.separator}>/</div>

                <ModalImage 
                    className={classes.hidden}
                    small={documents[2].title}
                    large={documents[2].large}
                    alt={documents[2].title}
                    hideDownload={true}
                />
            </div>

			<div>
				Други полезни документи може да откриете <Link className={classes.link} to="/documents">тук</Link> или <Link className={classes.link} to="/contacts">в нашия офис</Link>
			</div>

			<div className={classes.logoLine}>
				<img src={etravelLogo} className={classes.footerLogo} /> 
                 
				<span>© 2022 e-travel club</span>
			</div>
		</footer>
	)
}

export default Footer;
