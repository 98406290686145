// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kWqVI_WubKlsRwyX2_aQ{background:none;border:1px solid #444547;min-width:150px;height:50px;padding:0 20px;color:#444547;cursor:pointer;text-align:center;font-size:14px;line-height:1;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-transition:all .1s;transition:all .1s;text-decoration:none}.kWqVI_WubKlsRwyX2_aQ:hover{color:#bbbcbc}.kWqVI_WubKlsRwyX2_aQ:focus{outline:none}", "",{"version":3,"sources":["webpack://./client/components/Button/style.scss"],"names":[],"mappings":"AACA,sBACC,eAAA,CACA,wBAAA,CACA,eAAA,CACA,WAAA,CACA,cAAA,CACA,aAAA,CACA,cAAA,CACA,iBAAA,CACA,cAAA,CACA,aAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,0BAAA,CAAA,kBAAA,CACG,oBAAA,CAEH,4BAEC,aAAA,CAGD,4BACC,YAAA","sourcesContent":["\n.button {\n\tbackground: none;\n\tborder: 1px solid #444547;\n\tmin-width: 150px;\n\theight: 50px;\n\tpadding: 0 20px;\n\tcolor: #444547;\n\tcursor: pointer;\n\ttext-align: center;\n\tfont-size: 14px;\n\tline-height: 1;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\ttransition: all 0.1s;\n    text-decoration: none;\n\n\t&:hover {\n\n\t\tcolor: #BBBCBC;\n\t}\n\n\t&:focus {\n\t\toutline: none;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "kWqVI_WubKlsRwyX2_aQ"
};
export default ___CSS_LOADER_EXPORT___;
