// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--text: #414042}.ZlTBfnQuDdxrsgnZvRUg{margin:30px 30px}.Mn7KTZjwwUw51GyT3f7I{font-weight:bold;text-decoration:none;line-height:24px;color:#58595c}.Mn7KTZjwwUw51GyT3f7I:hover{text-decoration:underline;color:#bbbcbc}", "",{"version":3,"sources":["webpack://./client/styles/_variables.scss","webpack://./client/pages/Documents/styles.scss"],"names":[],"mappings":"AAAA,MACI,eAAA,CCCJ,sBACI,gBAAA,CAEJ,sBACI,gBAAA,CACA,oBAAA,CACA,gBAAA,CACA,aDaI,CCXJ,4BACI,yBAAA,CACA,aDWA","sourcesContent":[":root {\n    --text: #414042;\n}\n\n$bg: #FFFFFF;\n$TextColor: #414042;\n\n$Pink1: #AC3B61;\n$Pink2: #CE0F69;\n$Red1: #ED1C23;\n$Red2: #E4002B;\n$Purple1: #3F2A56;\n$Purple2: #6E4180;\n$Orange1: #F98E2B;\n$Yellow1: #FFD700;\n$Green1: #00843D;\n$Green2: #00B140;\n$Green3: #97D700;\n$Blue1: #24315E;\n$Blue2: #374686;\n$Blue3: #A8D1E7;\n$Gray0: #414042;\n$Gray1: #58595C;\n$Gray2: #97999B;\n$Gray3: #BBBCBC;\n$Gray4: #D0D0CE;\n$Gray5: #E8E8ED;\n$Gray6: #F3F3F6;\n$Gray7: #F2F2F6;\n$Gray8: #58595CCC;\n$Gray9: #F4F4F4;\n$White: #FFFFFF;\n\n$Beige1: #E5E2E2;\n$Beige2: #BAB2B5;\n$Beige3: #EDC7B7;","@import \"client/styles/variables.scss\";\n\n.documentsList {\n    margin: 30px 30px;\n}\n.textLink {\n    font-weight: bold;\n    text-decoration: none;\n    line-height: 24px;\n    color: $Gray1;\n\n    &:hover {\n        text-decoration: underline;\n        color: $Gray3;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"documentsList": "ZlTBfnQuDdxrsgnZvRUg",
	"textLink": "Mn7KTZjwwUw51GyT3f7I"
};
export default ___CSS_LOADER_EXPORT___;
