import { Carousel } from "react-responsive-carousel";
import Button from "../Button/Button";

import classes from "./styles.scss";

const images = [
    "https://etclub.bg/images/columbia-panama-2022/offer-valledecocora-large.jpeg",
    "https://etclub.bg/images/columbia-panama-2022/1651506422897.jpg",
    "https://etclub.bg/images/mexico-11-2022/zocalo-large.jpeg",
    "https://etclub.bg/images/mexico-11-2022/pyramid-chichen-itza-large.jpg"
];

const FrontPageCarousel = () => {
	return (
		<div className={classes.carousel}>
            <Carousel
                autoPlay={true}
                interval={2000}
                infiniteLoop={true}
                showThumbs={false}
                showArrows={true}
            >
                <div className={classes.carouselElement}>
                    <img src={images[0]} />      
                    
                    <div  className={classes.moreInfoButton}>
                        <div className={classes.destinationInfo}>Колумбия и Панама</div>

                        <Button
                            isRouterLink
                            label="Виж повече"
                            linkTo="/offers/panama-columbia-10-2022"
                            className={classes.ovalWhiteButton}
                        />
                    </div>
                </div>

                <div>
                    <img src={images[1]} />

                    <div  className={classes.moreInfoButton}>
                        <div className={classes.destinationInfo}>Колумбия и Панама</div>

                        <Button
                            isRouterLink
                            label="Виж повече"
                            linkTo="/offers/panama-columbia-10-2022"
                            className={classes.ovalWhiteButton}
                        />
                    </div>
                </div>

                <div>
                    <img src={images[2]}/>
         
                    <div  className={classes.moreInfoButton}>
                        <div className={classes.destinationInfo}>Мексико</div>

                        <Button
                            isRouterLink
                            label="Виж повече"
                            linkTo="/offers/mexico-11-2022"
                            className={classes.ovalWhiteButton}
                        />
                    </div>
                </div>

                <div>
                    <img src={images[3]} />
              
                    <div  className={classes.moreInfoButton}>
                        <div className={classes.destinationInfo}>Мексико</div>

                        <Button
                            isRouterLink
                            label="Виж повече"
                            linkTo="/offers/mexico-11-2022"
                            className={classes.ovalWhiteButton}
                        />
                    </div>
                </div>
            </Carousel>


        </div>
	)
}

export default FrontPageCarousel;
