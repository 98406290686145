import { Routes, Route } from "react-router-dom";

import AboutUs from "../pages/AboutUs/AboutUs";
import Contacts from "../pages/Contacts/Contacts";
import Documents from "../pages/Documents/Documents";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import Home from "../pages/Home/Home";
import Offer from "../pages/Offer/Offer";
import Offers from "../pages/Offers/Offers";
import OfferMexico from "../pages/OfferMexico/OfferMexico";
import ScrollToTop from "./ScrollToTop/ScrollToTop";

import classes from "./styles.scss";

import "../styles/index.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const App = () => {
    return (
        <ScrollToTop>
            <div className={classes.pageWrapper}>
                <Header />
        
                <main className={classes.main}>
                    <Routes>
                        <Route path="/" element={<Home />} />

                        <Route path="/about" exact element={<AboutUs />} />
        
                        <Route path="/contacts" exact element={<Contacts />} />
        
                        <Route path="/offers" exact element={<Offers />} />
        
                        <Route path="/offers/panama-columbia-10-2022" exact element={<Offer />} />

                        <Route path="/offers/mexico-11-2022" exact element={<OfferMexico />} />

                        <Route path="/documents" exact element={<Documents />} />
                    </Routes>
        
                </main>
        
                <Footer />
            </div>
        </ScrollToTop>
    )
}

export default App;
