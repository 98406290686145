const COLORS = {
    pink1: "#AA0061",
    pink2: "#CE0F69",
    red1: "#C8102E",
    red2: "#E4002B",
    purple1: "#3F2A56",
    purple2: "#6E4180",
    orange1: "#F98E2B",
    yellow1: "#FFD700",
    green1: "#00843D",
    green2: "#00B140",
    green3: "#97D700",
    blue1: "#264460",
    blue2: "#0077C8",
    blue3: "#00A3E0",
    gray0: "#444547",
    gray1: "#58595C",
    gray2: "#97999B",
    gray3: "#BBBCBC",
    gray4: "#D0D0CE",
    gray5: "#E8E8ED",
    gray6: "#F3F3F6",
    gray7: "#F2F2F6",
    gray8: "#58595CCC",
    gray9: "#F1F2F3",
    white: "#FFFFFF"
}

export default COLORS;
