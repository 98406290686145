import Card from "../../components/Card/Card";

import classes from "./styles.scss";

const Offers = () => {
    return (
        <div className={classes.offersWrapper}>
            <Card
                imageUrl="https://etclub.bg/images/columbia-panama-2022/offer-card-columbia-ricardo-gomez-angel-GcHar4P8V_Q-unsplash-small.png"
                mapUrl="https://etclub.bg/images/columbia-panama-2022/offer-columbia-card-map.png"
                title="Колумбия - Панама"
                daysCount={16}
                nights={15}
                startPoint="Богота"
                endPoint="Панама сити"
                price={9405}
                startDate="05.11"
                endDate="20.11"
                discountPercent={3.085}
                link={"/offers/panama-columbia-10-2022"}
            />
            <Card
                imageUrl="https://etclub.bg/images/mexico-11-2022/pyramid-chichen-itza-small.jpg"
                mapUrl="https://etclub.bg/images/mexico-11-2022/mexico.png"
                title="Мексико"
                daysCount={14}
                nights={11}
                startPoint="Мексико сити"
                endPoint="Канкун"
                price={6145}
                discountPercent={5.63}
                startDate="26.11"
                endDate="09.12"
                link={"/offers/mexico-11-2022"}
            />
        </div>
    )
}

export default Offers;
