import { Link } from "react-router-dom";

import Button from "../Button/Button";

import classes from "./styles.scss";

const Card = ({
    imageUrl,
    mapUrl,
    title,
    daysCount,
    countriesCount,
    startPoint,
    endPoint,
    price,
    startDate,
    endDate,
    nights,
    discountPercent,
    link
}) => {
    const discountPrice = (1 - discountPercent / 100) * price;

    return (
        <div className={classes.cardWrapper}>
            <div className={classes.imageContainer}>
                <Link to={link}>
                    <span className={`${discountPercent ? classes.specialOffer : classes.blockOffer}`}>Special Offer</span>

                    <img src={imageUrl} className={classes.mainImage} />

                    <img src={mapUrl} className={classes.mapImage} />

                    <div className={classes.imageBand}>Карта</div>
                </Link>
            </div>
            
            <h1 className={classes.title}>{title}</h1>

            <h3 className={classes.period}>{startDate} - {endDate}</h3>

            <div className={classes.rows}> 
                <span>{daysCount}{daysCount > 1 ? "дни" : "ден"}{countriesCount && ` / ${countriesCount}${countriesCount > 1 ? " държави" : " държава"}`}{nights && ` / ${nights}${nights > 1 ? "нощувки" : "нощувка"}`}</span>
                <span>Цена</span>
            </div>

            <div className={classes.rows}> 
                <span>Начало: {startPoint}</span>

                <span className={`${classes.price} ${discountPercent ? classes.discountPrice : ""}`}>{discountPercent ? `${Math.round(discountPrice)}лв` : `${price}лв`}</span>
            </div>

            <div className={classes.rows}> 
                <span>Край: {endPoint}</span>
                
                <span className={classes.oldPrice}>{discountPercent ? `${price}лв` : ""}</span>
            </div>

            <Button
                isRouterLink
                label="Виж повече"
                linkTo={link}
                className={classes.cardButton}
            />
        </div>
    )
}

export default Card;
