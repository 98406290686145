// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Xw3NIO0PF_PO4qecVAtO{height:40vw}", "",{"version":3,"sources":["webpack://./client/pages/Home/styles.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA","sourcesContent":[".homePagaCarousel {\n    height: 40vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homePagaCarousel": "Xw3NIO0PF_PO4qecVAtO"
};
export default ___CSS_LOADER_EXPORT___;
