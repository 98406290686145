// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".m3jkaaatRFQYLo8Ih4UN{display:-webkit-box;display:-ms-flexbox;display:flex;padding:50px 30px;gap:30px}@media screen and (max-width: 660px){.m3jkaaatRFQYLo8Ih4UN{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:50px}}", "",{"version":3,"sources":["webpack://./client/pages/Offers/styles.scss"],"names":[],"mappings":"AAAA,sBACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,iBAAA,CAEA,QAAA,CAIJ,qCACI,sBACI,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,QAAA,CAAA","sourcesContent":[".offersWrapper {\n    display: flex;\n    padding: 50px 30px;\n\n    gap: 30px;\n}\n\n\n@media screen and (max-width: 660px) {\n    .offersWrapper {\n        flex-direction: column;\n        align-items: center;\n        gap: 50px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offersWrapper": "m3jkaaatRFQYLo8Ih4UN"
};
export default ___CSS_LOADER_EXPORT___;
