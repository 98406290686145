import GoogleMapReact from "google-map-react";
import Pin from "../../components/Icons/Pin";
import officePictures from "../../components/images/office-picture.jpg"
import classes from "./styles.scss";

const AnyReactComponent = ({ text }) => <div className={classes.pin}>{text}</div>;

const Contacts = () => {
    const props = {
        center: {
          lat: 42.6949,
          lng: 23.3526
        },
        zoom: 16
    };

    return (
        <div className={classes.contactsContainer}>
            <div className={classes.officePictures}>
                <img src={officePictures} alt="image"/>
                <img src={officePictures} alt="image"/>
            </div>

            <div className={classes.contactsInfoMapContainer}> 
                <div className={classes.mapContainer}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyB_hgS465kzcRDiWbdRHgQQ8rfqm92hC5g" }}
                        defaultCenter={props.center}
                        defaultZoom={props.zoom}
                    >
                        <Pin
                            lat={42.694991}
                            lng={23.352658}
                        />
                    </GoogleMapReact>
                </div>

                <div className={classes.contactsInfo}>
                    <span className={classes.allCaps}>И-ТРАВЪЛ КЛЪБ ООД </span>

                    <span><b>Адрес:</b> гр. София 1505,</span>

                    <span>ул. Буная № 26, ателие 3</span>

                    <span><b>Телефони:</b> +359 898 707740, +359 2 4703360</span>

                    <span><b>Имейл:</b> office@etclub.bg</span>

                    <span className={classes.coordinates}>42.694991, 23.352658</span>
                </div>
            </div>
        </div>
    )
}

export default Contacts;
