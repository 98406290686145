import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import classes from "./style.scss";

const Button = (props) => {
    const {
        label,
        isLink,
        isRouterLink,
        isSubmitButton,
        linkTo,
        openInNewTab,
        onClick,
        className,
        disabled,
        ...otherProps
    } = props;

    const buttonClasses = [classes.button];

    if (className) {
        buttonClasses.push(className);
    }

    let linkProps = {};

    if (openInNewTab) {
        linkProps = {
            rel: "noreferrer",
            target: "_blank"
        };
    }

    let button;

    if (isLink) {
        button = (
            <a
                className={buttonClasses.join(" ")}
                href={linkTo}
                {...linkProps}
                {...otherProps}
            >
                {label}
            </a>
        )
	} else if (isRouterLink) {
		button = (
			<Link
			    className={buttonClasses.join(" ")}
				to={linkTo}
				{...linkProps}
				{...otherProps}
			>
				{label}
			</Link>
		);
	} else if (isSubmitButton) {
		button = (
			<input
				type="submit"
				disabled={disabled}
				className={buttonClasses.join(" ")}
				value={label}
				{...otherProps}
			/>
		);
	} else {
		button = (
			<button
				className={buttonClasses.join(" ")}
				onClick={onClick}
				disabled={disabled}
				{...otherProps}
			>
				{label}
			</button>
		);
	}

	return button;
}

Button.propTypes = {
	label: PropTypes.string.isRequired,
	isLink: PropTypes.bool,
	isRouterLink: PropTypes.bool,
	isSubmitButton: PropTypes.bool,
	openInNewTab: PropTypes.bool,
	linkTo: PropTypes.string,
	onClick: PropTypes.func,
	className: PropTypes.string
};

export default Button;
