import { useState } from "react";

import ChevronDown from "../Icons/ChevronDown";
import ChevronUp from "../Icons/ChevronUp";

import classes from "./styles.scss";

const CollapseSection = ({ day, title, content }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className={classes.collapseSection}>
            <div onClick={() => setIsActive(!isActive)} className={classes.dayWrapper}>
                <div className={classes.dayHeader}>
                    <div className={classes.day}>
                        <span className={classes.label}>Ден</span>

                        <span className={classes.value}>{day}</span>
                    </div>

                    <h3>{title}</h3>
                </div>

                <div className={classes.chevron}>{isActive ? <ChevronDown /> : <ChevronUp />}</div>
            </div>
            {isActive && <div className={classes.content}>{content}</div>}
        </div>
    )
}

export default CollapseSection;
