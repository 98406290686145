import React from "react";
import ReactDom from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { render } from "react-dom";
import App from "./components/App";

ReactDom.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);
