import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import etravelLogo from "../images/e-travel_logo_RGB.png";
import ClockIcon from "../Icons/ClockIcon";
import Phone from "../Icons/Phone";
import Envelope from "../Icons/Envelope";
import classes from "./styles.scss";
import COLORS from "../../utils/constants";
import Facebook from "../Icons/Facebook";
import Instagram from "../Icons/Instagram";
import RingingPhone from "../Icons/RingingPhone";

const Header = () => {
    const [menuIsVisible, setMenuIsVisible] = useState(false);

	useEffect(() => {
		if (menuIsVisible) {
			document.getElementsByTagName("html")[0].style.overflowY = "hidden";
		} else {
			document.getElementsByTagName("html")[0].style.overflowY = "scroll";
		}
		return () => {
			document.getElementsByTagName("html")[0].style.overflowY = "scroll";
		};
	}, [menuIsVisible]);

    const toggleMenu = (newPath) => {
		setMenuIsVisible((old) => !old);

		//if (newPath) {
		//	history.pushState(null, "/");
		//	history.pushState(null, newPath);
		//}
	};

    const onBurgerClickHandler = () => {
        if (window.innerWidth < 660) {
            setMenuIsVisible((old) => !old)
        }
    }

    return (
        <header className={classes.headerContainer}>
			<div className={classes.topRow}>
				<span>
                    <a href="https://www.facebook.com/E-Travel-club-108319548493995/" target="_blank">
					    <Facebook fill={COLORS.white} />
                    </a>

					<Instagram fill={COLORS.white} />
				</span>

				<span>
					<Envelope fill={COLORS.white} />

					<p>office@etclub.bg</p>
				</span>
			</div>

            <div className={classes.logoContactsSection}>
                <section>
					<Link to="/">
                    	<img src={etravelLogo} className={classes.headerLogo} />
					</Link>
                </section>

                <section className={classes.contactInfo}>
                    <div className={classes.iconInfo}>
                        <ClockIcon fill={COLORS.gray0} />

                        <div>
                            <p className={classes.bold}>Понеделник - Петък</p>

                            <p>9:00 - 18:00</p>
                        </div>
                    </div>

                    <div className={classes.iconInfo}>
                        <RingingPhone fill={COLORS.gray0}/>

                        <div>
                            <p className={classes.bold}>+359 898 707740</p>

                            <p>+359 2 4703360</p>
                        </div>
                    </div>
                </section>

                <div
			    	className={`${classes.hamburger} ${classes.hamburgerSpin} ${menuIsVisible ? classes.isActive : null}`}
			    	onClick={() => setMenuIsVisible((old) => !old)}
			    >
			    	<span className={classes.hamburgerBox}>
			    		<span className={classes.hamburgerInner}></span>
			    	</span>
			    </div>
            </div>
            
            <nav  className={`${classes.navSection} ${!menuIsVisible ? classes.hide : ""}`} onClick={onBurgerClickHandler}>
                <li>
					<Link to="/">Начало</Link>
				</li>

				<li>
					<Link to="/offers">Оферти</Link>
				</li>

				<li>
					<Link to="/about">За нас</Link>
				</li>

				<li>
					<Link to="/blog">Блог</Link>
				</li>

				<li>
					<Link to="/contacts">Контакти</Link>
				</li>
            </nav>
        </header>
    )
};

export default Header;
