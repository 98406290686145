import classes from "./styles.scss";

const Documents = () => {
    return (
        <div className={classes.documentsList}>
            <li><a className={classes.textLink} href={"https://etclub.bg/images/documents/InfoControl.docx"}>Информация за органи, контролиращи дейността</a></li>
            <li><a className={classes.textLink} href={"https://etclub.bg/images/documents/ConditionsETCLUB-BG.docx"}>Общи условия за използване на интернет страница</a></li>
            <li><a className={classes.textLink} href={"https://etclub.bg/images/documents/CookiesPolicy-BG.docx"}>Политика за „бисквитки“</a></li>
            <li><a className={classes.textLink} href={"https://etclub.bg/images/documents/PrivacyPolicy-BG.docx"}>Политика за поверителност</a></li>
            <li><a className={classes.textLink} href={"https://etclub.bg/images/documents/Identification-BG.docx"}>Идентификация</a></li>
            <li><a className={classes.textLink} href={"https://etclub.bg/images/documents/WithdrawalForm-BG.docx"}>Стандартен формуляр за упражняване правото на отказ от договора</a></li>
            <li><a className={classes.textLink} href={"https://etclub.bg/images/documents/ComplaintForm-BG.docx"}>Стандартен формуляр за упражняване правото на рекламация</a></li>
        </div>
    )
}

export default Documents;
