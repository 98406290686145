import { useState } from "react";

import ChevronDown from "../Icons/ChevronDown";
import ChevronUp from "../Icons/ChevronUp";

import classes from "./styles.scss";

const CollapseSectionSimple = ({ day, date, title, content }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className={classes.collapseSection}>
            <div onClick={() => setIsActive(!isActive)} className={classes.dayWrapper}>
                <div className={classes.dayHeader}>
                    <h3>{[day, title].filter(Boolean).join(" - ")}</h3>
                </div>

                <div className={classes.chevron}>{isActive ? <ChevronDown /> : <ChevronUp />}</div>
            </div>

            {isActive && <div className={classes.date}>{date}</div>}

            {isActive && <div className={classes.content}>{content}</div>}
        </div>
    )
}

export default CollapseSectionSimple;
