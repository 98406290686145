import classes from "./styles.scss";

const AboutUs = () => {
    return (
        <div className={classes.aboutUs}>
            <div className={classes.sectionHeader}>Няколко реда за нас</div>

            <div className={classes.sectionContent}>
                Рождената година на нашето дълго мечтано място за пътувания е 2009 г. Нарекохме го И-Травъл клъб 
                и го орисахме да носи сърцето и душата на своите създатели, защото държим да получите най-доброто 
                и да бъдете щастливи пътешественици. В ежедневната си работа влагаме много ентусиазъм и страст, 
                професионални знания и умения, лична грижа и отдаденост. За нас „отиването“ на друго място е една 
                внимателно планирана, добре реализирана и дълготрайна емоция, а не хаотично и безлично събиране 
                на услуги в едно цяло наречено „пакет“. Днес, години по-късно, сме горди, че приобщихме в „клуба“ 
                си хиляди хора – всички Вие, които пътувате с нас, наред с нашите сътрудници и партньори. 
                Заедно изповядваме подобно отношение към пътуването и Ви благодарим от сърце, че сте до нас!
                Много са усмихнатите лица от пропътуваните ни изживявания и това ни помагат да гледаме с 
                оптимизъм в бъдещето.
            </div>

            <div className={classes.sectionHeader}>Какво е за нас пътуването?</div>

            <div className={classes.sectionContent}>
                „Да пътуваш означава да живееш!“, казва  световноизвестният писател Ханс Кристиан Андерсен. Често си припомняме тези негови слова,
                защото вярваме, че пътуването ни прави по-щастливи, по-разбиращи света около нас и по-отговорни към това, което ще завещаем на
                идните поколения. Пътуването не е единствено престой в хубав хотел, разглеждане на забележителности и пазаруване на подаръци и
                сувенири. Те със сигурност са важни и ще Ви предоставим нашата най-добра селекция от услуги, но искаме да получите и преживяване,
                което да остави отпечат в съзнанието Ви. Има уникални багри навсякъде, вятърът духа по специфичен начин във всяко кътче, спиращи
                дъха залези има къде ли не, а приятели могат да се намерят по цялата земя. Всъщост това е нашата страст – искаме да сме откриватели.
                С нея поемаме отново и отново на път и сте добре дошли, да изследвате света с нас!
            </div>

            <div className={classes.columnsSection}>
                <div className={classes.column}>
                    <img src={`https://etclub.bg/images/documents/about-us-ivelin.jpeg`}></img>
                </div>
                <div className={classes.column}>
                    <span className={classes.header}>Нашите ценности</span>

                    <span className={classes.citatation}>
                        „Всички ние от И-Травъл клъб
                        работим усърдно, за да
                        изпълним мисията и
                        основните си ценности –
                        качество, новаторство,
                        отдаденост, доверие и лично
                        обслужване, и гарантираме, че
                        те ще съпътстват всеки
                        етап от Вашето пътуване“
                        Ивелин Цветков
                        Основател и Управител на И-
                        Травъл клъб ООД
                    </span>
                </div>

                <div className={classes.column}>
                    <span className={classes.header}>Качество</span>

                    <p className={classes.content}>
                        Качеството е личният ни
                        ангажимент и еталон за
                        развитието на И-Травъл клъб.
                        Намираме смисъл в
                        усещането, че вършим
                        нещата по най-добрия начин, а
                        не защото ще спестим време
                        и енергия или че ще Ви
                        предложим най-ниската цена.
                    </p>

                    <span className={classes.header}>
                        НОВАТОРСТВО И
                        ТВОРЧЕСТВО
                    </span>

                    <p className={classes.content}>
                        Творческата мисъл и
                        новаторството ни помагат
                        да създадем стойност на
                        това, което предлагаме.
                        Планираме лично всичките си
                        маршрути и разчитаме на
                        мрежата си от селектирани
                        местни и международни
                        туристически консултанти и
                        партньори. Често Вие сте
                        нашите генератори на идеи, а
                        друг път ни провокирате да
                        сътворим продукт, който би
                        паснал най-добре на личните
                        Ви нужди и изисквания.
                    </p>

                    <span className={classes.header}>
                        ВСЕОТДАЙНОСТ
                    </span>

                    <p className={classes.content}>
                        Посветени сме изцяло, за да
                        Ви осигурим възможно най-
                        доброто изживяване – от
                        момента на плануване, през
                        резервирането на услуги,
                        самото пътуване и до
                        завръщането у дома. Ние сме
                        тук, защото Ви има!
                    </p>
                </div>
                
                <div className={classes.column}>
                    <span className={classes.header}>ДОВЕРИЕ</span>

                    <p className={classes.content}>
                        Доверието е съществен
                        елемент от
                        взаимодействието с Вас.
                        Държим на честността,
                        добрия тон в дискусията и
                        следваме приципите и
                        обещанията си. Така ще сте
                        спокойни и ще се насладите
                        максимално на услугите ни, а
                        ние ще открием нов приятел.
                        И-Травъл клъб притежава
                        Удостоверение за
                        туроператорска и агентска
                        дейност и застрахователна
                        полица „Отговорност на
                        туроператора“.
                        Професионалната ни дейност
                        е високо оценена и от нашите
                        дългогодишни клиенти и
                        партньори.
                    </p>

                    <span className={classes.header}>
                        ЛИЧНО ОБСЛУЖВАНЕ
                    </span>

                    <p className={classes.content}>
                        Искаме и можем да Ви
                        изслушаме, ще Ви
                        предоставим най-точната
                        информация с ясни
                        инструкция. Осъзнаме, че
                        това е Вашето пътуване и
                        сме Ви нужни във всеки един
                        момент. Обичаме това, което
                        правим – творим,
                        проверяваме, препроверяваме,
                        пре-пре-препроверяваме,
                        доизпипваме всеки детайл. С
                        какво можем да сме Ви
                        полезни?
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;
